<template>
  <div class="blog-page">
    <NavBar />
    <h1>Blog</h1>
    <p>Welcome to my blog. Here I share insights and updates on various topics.</p>
  </div>
</template>

<script>
export default {
  name: 'BlogPage'
}
</script>

<style scoped>
.blog-page {
  padding: 20px;
  text-align: center;
  margin-top: 2rem; /* Adjust as needed */
  padding-top: 2rem; /* Adjust as needed */
}
</style>
