<template>
    <div class="container mt-5">
      <NavBar />
      <h1 class="text-center mb-4">Contact Me</h1>
      <div class="row">
        <div class="col-md-6">
          <form @submit.prevent="submitForm" class="mb-3">
            <div class="mb-3">
              <label for="name" class="form-label">Your Name</label>
              <input type="text" class="form-control" id="name" v-model="contact.name" required>
            </div>
            <div class="mb-3">
              <label for="email" class="form-label">Your Email</label>
              <input type="email" class="form-control" id="email" v-model="contact.email" required>
            </div>
            <div class="mb-3">
              <label for="message" class="form-label">Your Message</label>
              <textarea class="form-control" id="message" v-model="contact.message" rows="3" required></textarea>
            </div>
            <button type="submit" class="btn btn-primary">Send Message</button>
          </form>
        </div>
        <div class="col-md-6">
          <div class="mb-3">
            <h3>Social Media</h3>
            <a href="https://www.linkedin.com/in/ashhad-waquas-syed/" target="_blank" class="btn btn-outline-info m-1"><i class="fab fa-linkedin"></i> LinkedIn</a>
            <a href="https://github.com/waquas9248" target="_blank" class="btn btn-outline-secondary m-1"><i class="fab fa-github"></i> GitHub</a>
            <a href="https://x.com/SyedAshhad7419" target="_blank" class="btn btn-outline-primary m-1"><i class="fab fa-twitter"></i> Twitter</a>
          </div>
          <div>
            <h3>Contact Information</h3>
            <p><strong>Email:</strong> ashhadwsyed@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
<script>
    export default {
    name: 'ContactPage',
    data() {
        return {
        contact: {
            name: '',
            email: '',
            message: ''
        }
        };
    },
    methods: {
        submitForm() {
        console.log('Sending:', this.contact);
        alert("Message sent!");
        this.contact = { name: '', email: '', message: '' }; // Reset form after submission
        }
    }
    }
</script>

<style scoped>

@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css";


.container {
  margin-top: 2rem; /* Adjust as needed */
  padding-top: 2rem; /* Adjust as needed */
}

</style>