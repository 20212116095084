<template>
    <div class="portfolio-page">
      <NavBar />
      <h1>Portfolio</h1>
      <p>Here are some of my professional and personal projects.</p>
      <div class="projects-container">
        <div class="card" v-for="project in projects" :key="project.id">
          <img :src="project.image" :alt="project.title + ' image'" class="project-image">
          <div class="project-title">{{ project.title }}</div>
          <button class="view-button" @click="openProject(project.link)">
            View Code <i class="fas fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PortfolioPage',
    data() {
      return {
        projects: [
          { id: 1, title: 'Mediscan - Smart Doctor', image: '/medscan.png', link: 'https://github.com/waquas9248/AIHealthAssistant' },
          { id: 2, title: 'International Student Hub', image: '/studhub.png ', link: 'https://github.com/waquas9248/StudentHub' },
          { id: 3, title: 'Big Data Modelling - Image Classification', image: '/bdo.png', link: 'https://github.com/waquas9248/Big-Data-Organization' },
          { id: 4, title: 'QuickConnect', image: '/quickconnect.png', link: 'https://github.com/waquas9248/QuickConnect' },
          { id: 5, title: 'Outlier Detection - Transaction Classification', image: '/dm.png', link: 'https://github.com/waquas9248/SE512' },
          { id: 6, title: 'Natural Language Processing - Reddit', image: '/nlp.png ', link: 'https://github.com/waquas9248/Suicide-Intent-Detection-NLP' },
          { id: 7, title: 'Movie Theater', image: '/movietheater.png', link: 'https://github.com/waquas9248/MovieTheaterSystem' }]
      };
    },
    methods: {
      openProject(url) {
        window.open(url, '_blank');
      }
    }
  }
  </script>
  
  <style scoped>
  .portfolio-page {
    padding: 20px;
    text-align: center;
    margin-top: 2rem; /* Adjust as needed */
    padding-top: 2rem; /* Adjust as needed */
  }
  
  .projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    width: 200px;
    margin: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .project-image {
    width: 100%;
    height: 120px;
    object-fit: contain;
  }
  
  .project-title {
    height: 60px; /* Adjust based on your needs */
    display: flex;
    align-items: center; /* Centers text vertically */
    justify-content: center; /* Centers text horizontally */
    text-align: center;
    padding: 0 10px; /* Provides padding and prevents overflow */
    overflow: hidden; /* Hides any overflowing text */
  }
  
  .view-button {
    width: 100%;
    padding: 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 0 0 10px 10px; 
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .view-button:hover {
    background-color: #0056b3;
  }
  
  .fas {  
    margin-left: 5px;
  }
  </style>
  