import { createApp } from 'vue';
import App from './App.vue';
import router from './router';  
import NavBar from './components/NavBar.vue';  // Import NavBar component
import './assets/main.css'


const app = createApp(App);
app.component('NavBar', NavBar);  // Globally register the NavBar component
app.use(router);  // Use the router
app.mount('#app');