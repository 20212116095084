<template>
    <nav class="top-bar">
    <div class="logo">
        <a href="/" @click.prevent="$router.push('/')">
        <i class="fa-solid fa-house"></i>
        </a>
    </div>
    <div class="links">
        <router-link to="/portfolio" class="link" active-class="active-link" >Portfolio</router-link>
        <router-link to="/blog" class="link" active-class="active-link">Blog</router-link>
        <router-link to="/achievements" class="link" active-class="active-link">Achievements</router-link>
        <router-link to="/contactme" class="link" active-class="active-link">Contact Me</router-link>
    </div>
  </nav>
</template>



<script>
export default {
    name: 'NavBar'
}
</script>


<style scoped>
@import '@fortawesome/fontawesome-free/css/all.css';

.top-bar {
    padding: 10px; /* Unified padding */
    font-size: 25px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #007BFF; /* Primary color */
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.top-bar a, .top-bar .link {
    display: inline-block;
    padding: 10px 20px;
    color: white; /* Ensure color is set for all links */
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
}

.logo a, .link {
    background-color: transparent; /* Avoid overriding styles */
}

.logo a:hover i {
    color: #ccc; /* Specific style for hovering over the home icon */
}

.links {
    display: flex;
    justify-content: space-between;
}

.link {
    margin-right: 20px;
}

.link, .link:active, .link:hover, .active-link {
    color: white;  /* Default color for links */
    text-decoration: none;
    transition: color 0.3s;
}


.link:hover, .active-link {
    color: #ccc; /* Set hover and active color the same */
}

.active-link {
    color: #ccc; /* Ensure active link retains the color */
}
</style>
