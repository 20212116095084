<template>
 
    <NavBar />

    <!-- Main Content -->
    <div class="main-content">
      <!-- Sidebar -->
      <div class="sidebar">
        <div class="section-link" id="about-me-link" data-section="about-me">
          <span class="dash"></span>
          <span class="section-name">About Me</span>
        </div>
        <div class="section-link" id="education-link" data-section="education">
          <span class="dash"></span>
          <span class="section-name">Education</span>
        </div>
        <div class="section-link" id="experience-link" data-section="experience">
          <span class="dash"></span>
          <span class="section-name">Experience</span>
        </div>
        <div class="section-link" id="skills-link" data-section="skills">
          <span class="dash"></span>
          <span class="section-name">Skills</span>
        </div>
        <div class="section-link" id="interests-link" data-section="interests">
          <span class="dash"></span>
          <span class="section-name">Interests</span>
        </div>
      </div>

      <!-- Sections -->
      <div class="sections">
        <!-- About Me -->
        <div class="section" id="about-me">
          <div class="text-container">
            <h2>About Me</h2>
            <p><br>Hey, I'm Ashhad, I love designing software applications through methodical practices and bringing them to life with robust coding techniques</p>
            <p><br>I am a programmer and problem-solver in all sense, I have worked on a variety of projects that have helped me grow as a holistic software developer</p>
            <p><br>From building user-facing full stack web applications, training and fine-tuning ML models, crafting data pipelines, exploring and visualizing complex data to researching AI theories and principles within NLP and Vision, I have done it all!</p>
          </div>
          <div class="image-container">
            <img src="image1.png" alt="Image 1">
          </div>
        </div>

        <!-- Education -->
        <div class="section" id="education">
          <h2>Education</h2>
          <div class="education-container">
            <div class = "education-subsection">
              <h3> <br>Saint Cloud State University </h3>  
              <p> Masters in Computer Science</p>
              <img src="Campus-SCSU.png" alt="Image 3">
            </div>
            <div class = "education-subsection">
              <h3> <br>Osmania University </h3>  
              <p> Bachelors in Computer Science </p>
              <img src="MJCET.png" alt="Image 4">
            </div>
          </div>
        </div>

        <!-- Experience -->
        <div class="section" id="experience">
          <div class="text-container">
            <h2>Experience</h2>
            <h3><br> Software Engineer - EdTech Innovations at SCSU </h3>
            <p>Built AI chatbot applications, data pipelines for university data API sources among other cool stuff!</p>
            <h3><br> Graduate Teaching Assistant - Dept of CSIT at SCSU  </h3>
            <p> Research and instruction for undergraduate CS students </p>
            <h3><br> Software Developer - Big Data Platform at AIS </h3>
            <p> Worked on data pipelines, ML models and data visualization tools </p>
          </div>
          <div class="image-container">
            <img src="MC220.png" alt="Image 5">
          </div>
        </div>

        <!-- Skills -->
        <div class="section" id="skills">
          <div class="text-container">
            <h2>Skills</h2>
            <p><br>I am a Computer Science aficionado. Out of curiosity and genuine interest, I pursued multiple domains of CS over the past 6 years</p>
            <p><br>I have worked the most with Java, Python, JavaScript in languages, Azure in cloud</p>
            <p>SpringBoot, Django, Node, React, Vue, MySQL, PostgreSQL in full stack web dev</p>
            <p>Scikitlearn, PyTorch, TensorFlow, HuggingFace, LangChain in ML </p>
            <p>Spark, Hadoop, Kafka, dbt, Airflow in Big Data and Data Engineering</p>
            <p>Tableau, PowerBI, D3, Matplotlib, Seaborn in Data Analysis</p>
            <p>Git, Docker, Kubernetes, Jenkins, Terraform in DevOps</p>
            <p><br>And many more, I am always learning and adapting to new technologies and paradigms</p>
          </div>
          <div class="image-container">
            <img src="cs.png" alt="Image 8">
          </div>
        </div>

        <!-- Interests -->
        <div class="section" id="interests">
          <div class="text-container">
            <h2>Interests</h2>
            <p><br>Outside of technology, I enjoy weight training, athletics, reading beneficial books</p>
          </div>
          <div class="image-container">
            <img src="gym.png" alt="Image 9">
          </div>
        </div>
      </div>
    </div>

 
</template>



<script>
export default{
    mounted() {

    // Get all section links
    const sectionLinks = document.querySelectorAll('.section-link');

    // Add event listener to each section link
    sectionLinks.forEach((link) => {
      link.addEventListener('click', () => {
        // Get the section ID from the data-section attribute
        const sectionId = link.getAttribute('data-section');
        console.log(sectionId);
        // Get the section element
        const section = document.getElementById(sectionId);
        // Scroll to the section
        section.scrollIntoView({ behavior: 'smooth',  block: 'center' });
        // Remove active class from all section links
        sectionLinks.forEach((otherLink) => {
          otherLink.classList.remove('active');
        });
        // Add active class to the clicked section link
        link.classList.add('active');
      });
    });

    // Add event listener to the window to detect scroll position
    window.addEventListener('scroll', () => {
      // Get the current scroll position
      // const scrollPosition = window.scrollY;
      // Get the sections
      const sections = document.querySelectorAll('.section');
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        const sectionLink = document.querySelector(`.section-link[data-section="${section.id}"]`);
        if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
          // Add active class to the section link
          sectionLink.classList.add('active');
        } else {
          // Remove active class from the section link
          sectionLink.classList.remove('active');
        }
      });
    });

  }

}
</script>


<style scoped>

@import url('https://fonts.googleapis.com/css?family=Merriweather|Montserrat');
@import url('https://fonts.googleapis.com/css?family=Inter:400,700&display=swap');

/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Merriweather', sans-serif;
  color: #333;
  background-color: #f9f9f9;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
}

h3 {
  font-size: 1.2em;
}

p {
  font-family: 'Inter', sans-serif;
  font-weight: 400; /* regular weight */
  font-style: normal; /* normal style */
  color: #333;
  background-color: #ffffff;
}

/* Navbar (already existing) */
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 1em;
  text-align: center;
}

/* Main Content */
.main-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2em;
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
  background-color: transparent;
  padding: 20px;
  border-radius: 10px;
  margin-left: 25px; 
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.section-link {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  cursor: pointer;
}

.dash {
  width: 5px;
  height: 20px;
  background-color: #ccc;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.section-name {
  font-size: 20px;
  color: #333;
}

.section-link:hover .dash {
  background-color: #84b9f1;
}

.section-link.active .dash {
  background-color:  #007BFF;
}

.section-link.active .section-name {
  color: #666;
}

/* Sections */
.sections {
  width: 100%;
  padding: 2em;
  padding-top: 60px;
}

.section {
  background-color: #fff;
  padding: 1.5em;
  margin-bottom: 2em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 200px;
  display: flex;
}

#education {
  display: flex;
  flex-direction: column;
}

.text-container {
  width: 50%;
  padding: 1em;
}

.text-container p {
  text-align: justify;
}


.image-container {
  width: 50%;
  padding: 1em;
}

.image-container img {
  width: 100%;
  height: 80%;
  object-fit: cover;
  border-radius: 10px;

}


.education-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.education-subsection {
  flex-basis: 45%;
  margin: 0px;
  padding: 20px;
}

.education-subsection h3 {
  margin-bottom: 10px;
}

.education-subsection p {
  margin-bottom: 20px;
}

.education-subsection img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

</style>