import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';  
import AboutMePage from '../views/AboutMePage.vue';
import AchievementsPage from '../views/AchievementsPage.vue';
import PortfolioPage from '../views/PortfolioPage.vue';
import BlogPage from '../views/BlogPage.vue';
import ContactPage from '../views/ContactPage.vue';

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/AboutMe',
    name: 'AboutMePage',
    component: AboutMePage
  },
  {
    path: '/Achievements',
    name: 'AchievementsPage',
    component: AchievementsPage
  },
  {
    path: '/Portfolio',
    name: 'PortfolioPage',
    component: PortfolioPage
  },
  {
    path: '/Blog',
    name: 'BlogPage',
    component: BlogPage
  },
  {
    path: '/ContactMe',
    name: 'ContactPage',
    component: ContactPage
  }

  // Add other routes here
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
