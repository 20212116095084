<template>
  <div class="home-page">
    <div class="content">
      <h1>Hello, I'm Ashhad</h1>
      <p class="tagline">Innovating at the intersection of Technology and Education</p>
      <div class="buttons">
        <button class="button main-button" @click="goToAbout">About Me</button>
        <button class="button main-button" @click="goToPortfolio">View My Portfolio</button>
        <button class="button secondary-button" @click="goToBlog">Blog</button>
        <button class="button secondary-button" @click="goToAchievements">Achievements</button>
        <button class="button secondary-button" @click="goToContact">Contact Me</button>
      </div>
    </div>
    <!-- Three.js canvas -->
    <div ref="threeCanvas" class="three-canvas"></div>
  </div>
</template>

<script>
import * as THREE from 'three';
import { useRouter } from 'vue-router';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { markRaw } from 'vue';

export default {
  name: 'HomePage',
  setup() {
    const router = useRouter();
    return { router };
  },
  data() {
    return {
      camera: null,
      renderer: null,
      scene: null,
    };
  },
  mounted() {
  this.setupThreeJS();
  window.addEventListener('resize', this.onWindowResize);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onWindowResize);
  },

  methods: {
    goToAbout() {
      this.router.push('/AboutMe');
    },
    goToPortfolio() {
      this.router.push('/Portfolio');
    },
    goToBlog() {
      this.router.push('/Blog');
    },
    goToAchievements() {
      this.router.push('/Achievements');
    },
    goToContact() {
      this.router.push('/ContactMe');
    },
    setupThreeJS() {
      const canvasContainer = this.$refs.threeCanvas;
      const width = canvasContainer.clientWidth;
      const height = canvasContainer.clientHeight;

      // Create a scene
      const scene = new THREE.Scene();

      // Setup camera
      const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
      camera.position.set(0, 2, 5); // Adjust camera position based on the model

      // Setup renderer
      const renderer = new THREE.WebGLRenderer({ antialias: true });
      renderer.setSize(width, height);
      renderer.setClearColor(0xf0f0f0);  // Set a light gray background, or choose any color you prefer
      canvasContainer.appendChild(renderer.domElement);


      // Load the GLB model
      const loader = new GLTFLoader();
      loader.load(
        '/workdesk.glb',  // Path to your office desk model
        (gltf) => {
          this.model = markRaw(gltf.scene);
          this.model.position.set(0, 0, 0);
          this.model.scale.set(1, 1, 1);
          scene.add(this.model);
        },
        undefined,  // We can define a function here to track progress
        (error) => {
          console.error('An error happened during the loading of the model:', error);
        }
      );

      const ambientLight = new THREE.AmbientLight(0xffffff, 0.7);  // Soft white light
      scene.add(ambientLight);

      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
      directionalLight.position.set(1, 2, 3);  // Adjust direction as needed
      scene.add(directionalLight);


      // Animation loop
      const animate = () => {
        requestAnimationFrame(animate);

        if (this.model) {
          this.model.rotation.y += 0.01;  // This line will continuously rotate the model
        }

        renderer.render(scene, camera);
      };

      animate();
    },
    onWindowResize() {
      if (this.camera && this.renderer) {
        const canvasContainer = this.$refs.threeCanvas;
        const newWidth = canvasContainer.clientWidth;
        const newHeight = canvasContainer.clientHeight;

        this.camera.aspect = newWidth / newHeight;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(newWidth, newHeight); // Ensure the renderer resizes exactly to the container's dimensions

      }
    }

  }
}
</script>

<style scoped>

html, body {
   margin: 0;
   padding: 0;
   height: 100vh;  
   width: 100vw;   
   overflow: hidden;  
}


.home-page {
  display: flex;
  flex-direction: column; /* If you want the content stacked vertically */
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 100vh; /* Use min-height to ensure it covers at least the viewport height */
}


.content {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 80%; 
  z-index: 2; 
  text-align: center;
}

.buttons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;  
}


.button {
  padding: 15px 30px;
  font-size: 20px;
  cursor: pointer;
  transition: transform 0.3s;
  margin: 5px;
  border: none;
  border-radius: 5px;
}

.main-button {
  background-color: #007BFF;
  color: white;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.4);
}

.main-button:hover {
  transform: scale(1.1);
  color: #ccc;

  transition: color 0.3s;
  text-decoration: none;
}

.secondary-button {
  background-color: #6c757d;
  color: white; 
  
}

.secondary-button:hover {
  background-color: #5a6268;
  color: #ccc;

  transition: color 0.3s;
  text-decoration: none;
}

.three-canvas {
  position: fixed;  
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; 
  z-index: 1;
}


</style>
